@import "../base";
.dashboard {
    width: 100%;
    padding: 0% 5%;

    .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        padding-top: 2%;
        .item {
            width: 29%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 2%;
            border-radius: 10px;
            transition: all ease-in-out 0.2s;
            h6 {
                font-size: 0.8rem;
                font-weight: 200;
            }
            &:hover {
                cursor: pointer;
                scale: 1.01;
                transform: translateY(-1px);
            }
        }
        .revenue {
            background-color: $brightpink;
        }
        .expenses {
            background-color: $darkgray;
        }
        .people {
            background-color: $darkgreenblue;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .chart {
                width: 1px;
                height: 1px;
            }
            &:hover {
                .chart {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
    .main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        height: 70%;
        align-items: center;
        .course-main {
            text-decoration: none;
            color: black;
            width: 60%;
            height: 100%;
            border-radius: 20px;
            background: linear-gradient(to right, #929489, #999b90, #aeb0a1);
            position: relative;
            overflow: hidden;
            padding: 3% 3%;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            transition: all ease-in-out 0.2s;
            &:hover {
                cursor: pointer;
                &::after {
                    width: 320px;
                    height: 320px;
                    right: 10px;
                    bottom: 10px;
                    transform: rotate(370deg);
                }
            }
        }

        .course-main::before,
        .course-main::after {
            content: "";
            position: absolute;
            border-radius: 50%;
            background: inherit;
        }
        .course-main::after {
            transition: all cubic-bezier(0.69, 0.04, 0, 1.17) 1.5s;
            width: 300px;
            height: 300px;
            right: 10px;
            bottom: 10px;
            transform: rotate(20deg);
        }
    }
    .courses {
        gap: 0px;
        height: 100%;
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        a {
            text-decoration: none;
            color: black;
            width: 100%;
            display: flex;
            padding: 5% 5%;
            border-radius: 10px;
            height: 30%;
            transition: all ease-in-out 0.2s;
            &:hover {
                cursor: pointer;
                scale: 1.01;
                transform: translateY(-1px);
            }
        }
        .python {
            background-color: $orange;
        }
        .ui {
            background-color: $lightgray;
        }
        .robotics {
            background-color: $darkpink;
        }
    }
}
