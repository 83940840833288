@import "../base";
.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    input {
        width: 30%;
        height: 50px;
        border: 1px solid $verylightgray;
        border-radius: 10px;
        padding: 0% 2%;
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 2%;
        transition: all ease-in-out 0.2s;
        &:hover {
            cursor: pointer;
            scale: 1.01;
            transform: translateY(-1px);
        }
    }
    button {
        width: 10%;
        margin-top: 1%;
        height: 50px;
        border: 1px solid $verylightgray;
        border-radius: 10px;
        padding: 0% 2%;
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 10px;
        transition: all ease-in-out 0.2s;
        &:hover {
            cursor: pointer;
            scale: 1.01;
            transform: translateY(-1px);
        }
    }
}
