@import "../base";
.students {
    width: 100%;
    padding: 0% 5% 5% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .search {
        margin-top: 3%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        input {
            width: 85%;
            height: 50px;
            border: 1px solid $verylightgray;
            border-radius: 10px;
            padding: 0% 2%;
            font-size: 1rem;
            font-weight: 300;
        }
        button {
            width: 10%;
            border: 2px solid $darkgreenblue;
            background: none;
            border-radius: 10px;
            color: $darkgreenblue;
            font-size: 1rem;
            transition: all ease-in-out 0.2s;
            &:hover {
                cursor: pointer;
                background-color: $darkgreenblue;
                transform: translateY(-1px);
                scale: 1.01;
                color: white;
            }
        }
    }

    .loader {
        position: absolute;
        top: calc(50% - 32px);
        left: calc(50% - 32px);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        perspective: 800px;
    }

    .inner {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .inner.one {
        left: 0%;
        top: 0%;
        animation: rotate-one 1s linear infinite;
        border-bottom: 3px solid $darkgreenblue;
    }

    .inner.two {
        right: 0%;
        top: 0%;
        animation: rotate-two 1s linear infinite;
        border-right: 3px solid $darkgreenblue;
    }

    .inner.three {
        right: 0%;
        bottom: 0%;
        animation: rotate-three 1s linear infinite;
        border-top: 3px solid $darkgreenblue;
    }
}
