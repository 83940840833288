@import "../base";
.notify {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .notify-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 70%;
        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
            textarea {
                width: 100%;
                height: 50%;
                padding: 10px;
                margin: 10px 0;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 17px;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif, sans-serif;
            }
            button {
                height: 15%;
                width: 100%;
                background-color: $lightgray;
                border-radius: 15px;
                border: none;
                transition: all ease-in-out 0.3s;
                font-size: 18px;
                &:hover {
                    background-color: $darkgray;
                    cursor: pointer;
                }
            }
        }
    }
}
