@import "base";

$primary: rgb(255, 255, 255);
$secondary: rgb(250, 250, 250);
$on-primary: rgb(50, 49, 55);
$accent: rgb(119, 120, 131);
$border: rgb(238, 238, 238);

.menu {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $secondary;
    border-top: 0.1px solid $border;
    font-family: "Raleway", sans-serif;

    .menu-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        div {
            margin: 2% 3%;
            border-radius: 999px;
            padding: 12px;

            .icon {
                width: 25px;
                height: 25px;
                stroke-width: 1.5px;
                color: $accent;
            }

            transition: all 10s ease-in-out;

            &:hover {
                background-color: $border;
                cursor: pointer;
            }
        }
    }
}

.sidebar {
    height: 100vh;
    width: 17%;
    position: sticky;
    font-family: "Raleway", sans-serif;

    background-color: $secondary;
    border-right: 0.1px solid $border;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 2%;

    .title {
        width: 100%;
        color: $on-primary;

        h1 {
            font-size: 2.5vw;
            font-weight: 900;
        }
    }

    .items {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 25%;

        .item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 7.5% 5%;
            border-radius: 7px;
            transition: all ease-in-out 0.3s;
            color: $accent;
            text-decoration: none;

            h1 {
                font-size: 1vw;
                font-weight: 500;
                margin-left: 10px;
            }

            .icon {
                stroke-width: 1.5px;
                width: 25px;
                height: 25px;
                color: $accent;
            }

            &:hover {
                background-color: $border;
                cursor: pointer;
            }
        }
    }
}
