$brightpink: #fbbcc1;
$darkpink: #9d717a;
$lightgray: #e6dcd7;
$darkgray: #ddcfc8;
$darkgreenblue: #9badb4;
$orange: #fba48c;
$verylightgray: rgb(231, 232, 233);

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@font-face {
    font-family: "Code Pro";
    src: url("../../utils/CodeProLC.otf");
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
}
