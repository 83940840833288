@import "../base";
#course {
    width: 100%;
    .courses-row {
        padding-left: 2%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 3%;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        border-top: 1px solid $darkgray;
        padding-top: 1vw;
        .groups {
            display: flex;
            flex-wrap: wrap;

            a {
                text-decoration: none;
                margin-right: 5vw;
                margin-top: 1vw;
                border: 1px solid $verylightgray;
                border-radius: 10px;
                padding: 2vw 1.5vw;
                transition: all ease-in-out 0.2s;
                width: 25%;
                h1 {
                    color: $darkpink;
                }
                h6 {
                    color: $darkgreenblue;
                }
                &:hover {
                    cursor: pointer;
                    scale: 1.01;
                    transform: translateY(-1px);
                }
                .actions {
                    display: flex;
                    justify-content: space-start;
                    margin-top: 1vw;
                    button {
                        width: 20%;
                        border: 2px solid $darkgreenblue;
                        background: none;
                        border-radius: 5px;
                        color: $darkgreenblue;
                        font-size: 1rem;
                        transition: all ease-in-out 0.2s;
                        margin-right: 10%;
                        &:hover {
                            cursor: pointer;
                            background-color: $darkgreenblue;
                            transform: translateY(-1px);
                            scale: 1.01;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.students-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    .card {
        width: 100%;
        border: 1px solid $verylightgray;
        border-radius: 15px;
        padding: 5% 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all ease-in-out 0.2s;
        h1 {
            font-size: 14px;
            font-weight: 300;
            color: rgb(161, 161, 161);
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
        }
        .rings {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            padding: 5% 10%;
        }
        .icon {
            width: 45%;
            img {
                width: 100%;
            }
            margin-bottom: 10px;
        }
        .actions {
            margin-top: auto;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            padding: 19% 10% 0% 10%;
            button {
                width: 25%;
                height: 50px;
                border: none;
                border-radius: 10px;
                padding: 0% 2%;
                font-size: 1rem;
                font-weight: 300;
                margin-bottom: 10px;
                transition: all ease-in-out 0.2s;
                &:hover {
                    cursor: pointer;
                    scale: 1.01;
                    transform: translateY(-1px);
                }
            }
            .edit {
                background-color: rgb(222, 222, 129);
            }
            .delete {
                background-color: rgb(196, 81, 81);
            }
        }

        &:hover {
            cursor: pointer;
            scale: 1.01;
            transform: translateY(-1px);
            box-shadow: rgba(0, 0, 0, 0.15) 0.8px 0.8px 1px;
        }
    }
}

.group {
    padding: 3% 5%;
    width: 100%;
    .group-data {
        display: flex;
        width: 100%;

        justify-content: space-between;
        align-items: center;
        .group-actions {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            button {
                width: 20%;
                height: 50px;
                border: 1px solid $verylightgray;
                border-radius: 10px;
                padding: 0% 2%;
                font-size: 1rem;
                font-weight: 300;
                margin-bottom: 10px;
                transition: all ease-in-out 0.2s;
                &:hover {
                    cursor: pointer;
                    scale: 1.01;
                    transform: translateY(-1px);
                }
            }
            .add,
            .remove,
            .quick-action {
                background-color: rgb(103, 162, 103);
                color: white;
                font-size: 30px;
            }
            .remove {
                background-color: rgb(179, 78, 78);
            }

            .quick-action {
                background-color: rgb(76, 126, 244);
            }
        }
        .group-info {
            width: 75%;
            h1,
            h2 {
                font-size: 2.5vw;
                color: $darkpink;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
            }
            h2 {
                color: $darkgreenblue;
            }
            h6,
            h5 {
                font-size: 1.5vw;
                color: $darkgreenblue;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
            }
            h5 {
                color: $darkpink;
            }
        }
    }
}
